<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card grid-list-md>
      <ModelTitle title="Edit Customer" @close="close()" />
      <v-card-text>
        <v-row>
          <v-col cols="12" xs="12" sm="12">
            <v-text-field :error-messages="nameErrors" v-model="customer.name" label="Name"
              @change="customer.name = properCase(customer.name)" :hide-details="nameErrors.length === 0" outlined
              @focus="$event.target.select()" dense></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-text-field v-model="customer.contactName" outlined hide-details
              @change="customer.contactName = properCase(customer.contactName)" dense @focus="$event.target.select()"
              label="Contact Name"></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-text-field outlined dense hide-details class="right-input" @focus="$event.target.select()"
              v-model="customer.balanceLimit" label="Balance Limit"></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-text-field outlined hide-details dense @focus="$event.target.select()" v-model="customer.cellNumber"
              label="Cell Number"></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-text-field outlined hide-details dense @focus="$event.target.select()" v-model="customer.phoneNumber"
              label="Phone Number"></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-text-field outlined hide-details dense v-model="customer.email" label="Email Address"></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-text-field outlined hide-details dense v-model="customer.taxId" label="Tax ID"></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <DayPicker v-model="customer.metadata.invoiceDue" :initValue="customer.metadata.invoiceDue" :title="'Customer Terms'" />
          </v-col>
          <v-col cols="12" sm="12">
            <v-card outlined>
              <v-tabs v-model="selectedTab">
                <v-tab>Billing</v-tab>
                <v-tab>Shipping</v-tab>
                <v-tab-item>
                  <v-container fluid>
                    <v-row>
                      <v-col cols="12" sm="12">
                        <v-text-field outlined hide-details dense v-model="customer.address"
                          label="Address"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field outlined hide-details dense @change="customer.city = properCase(customer.city)"
                          v-model="customer.city" label="City"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field outlined dense hide-details v-model="customer.state" label="State"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field outlined hide-details dense v-model="customer.zip"
                          label="Zip Code"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>
                <v-tab-item>
                  <v-container fluid>
                    <v-row>
                      <v-col cols="12" sm="12" v-if="customer.address">
                        <v-btn outlined small @click="copyBillingAddressToShipping" >Same as billing</v-btn>
                      </v-col>
                      <v-col cols="12" sm="12">
                        <v-text-field outlined hide-details dense v-model="customer.shippingAddress"
                          label="Address"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field outlined hide-details dense @change="customer.shippingCity = properCase(customer.shippingCity)"
                          v-model="customer.shippingCity" label="City"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field outlined dense hide-details v-model="customer.shippingState" label="State"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field outlined hide-details dense v-model="customer.shippingZip"
                          label="Zip Code"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>
              </v-tabs>
            </v-card>


          </v-col>


          <v-col cols="12" sm="12">
            <SalesMan v-model="selectedUser" :rounded="false" :initValue="this.customer.SalesRep" />
          </v-col>
          <v-col cols="12" sm="12">
            <v-textarea outlined hide-details dense auto-grow v-model="customer.note"
              :label="$t('labels.note')"></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <span class="mr-2">Balance</span>
        <toggle-button :width="60" :labels="{ checked: 'Show', unchecked: 'Hide' }" v-model="customer.showBalance"
          :value="customer.showBalance" :sync="true">
        </toggle-button>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close()">Close</v-btn>
        <v-btn color="pink lighten-1" :loading="loading" dark @click="submit()">Update</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import customerService from "../service.js";
import GlobalFunction from "@/mixins/GlobalFunction";
import SalesMan from "@/modules/User/components/AutoComplete.vue";
import DayPicker from "@/components/DayPicker";
import _ from "lodash";

const { validationMixin } = require("vuelidate");
const { required } = require("vuelidate/lib/validators");

export default {
  props: {
    editDialog: {
      default: false
    },
    editCustomer: {
      type: Object
    }
  },
  name: "update-customer",
  data() {
    return {
      selectedTab: 0,
      loading: false,
      dialog: false,
      selectedUser: null,
      customer: {
        showBalance: true,
        name: null,
        cellNumber: null,
        email: null,
        phoneNumber: null,
        contactName: null,
        address: null,
        city: null,
        state: null,
        zip: null,
        shippingAddress: null,
        shippingCity: null,
        shippingState: null,
        shippingZip: null,
        note: null,
        SalesRepUserId: null,
        metadata: {
          invoiceDue: null,
        }
      }
    };
  },
  mixins: [validationMixin, GlobalFunction],
  validations: {
    customer: {
      name: {
        required
      }
    }
  },
  watch: {
    editDialog(val) {
      this.customer = _.clone(this.editCustomer)
      if(!this.customer.metadata){
        this.customer.metadata = {
          invoiceDue: null
        }
      } else if(!this.customer.metadata.hasOwnProperty('invoiceDue')){
        this.customer.metadata.invoiceDue = null
      }
      this.dialog = val;
    },
    selectedUser(val) {
      this.customer.SalesRepUserId = val.id
    },
  },
  components: {
    SalesMan,
    DayPicker
  },
  computed: {
    ...mapGetters("global", ["currentUser"]),
    nameErrors() {
      const errors = [];
      if (!this.$v.customer.name.$dirty) return errors;
      if (!this.$v.customer.name.required)
        errors.push(this.$t("validations.fieldIsRequired"));

      return errors;
    }
  },
  methods: {
    copyBillingAddressToShipping() {
      this.customer.shippingAddress = this.customer.address
      this.customer.shippingCity = this.customer.city
      this.customer.shippingState = this.customer.state
      this.customer.shippingZip = this.customer.zip
    },
    close() {
      this.empty();
      this.$emit("close");
    },
    empty() {
      this.selectedTab = 0
      this.customer = {
        name: null,
        email: null,
        phoneNumber: null,
        contactName: null,
        address: null,
        city: null,
        state: null,
        zip: null,
        shippingAddress: null,
        shippingCity: null,
        shippingState: null,
        shippingZip: null,
        note: null,
        metadata: {
          invoiceDue: null,
        }
      };
      this.$v.$reset();
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields")
          });
      } else {
        this.loading = true;
        return customerService
          .update(this.customer.id, this.customer)
          .then(result => {
            if (result.status) {
              this.$swal
                .mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000
                })
                .fire({
                  icon: "success",
                  title: "Customer is updated!"
                });
              this.loading = false;
              this.$emit("submit");
              this.$events.emit("customerListEvent");

              this.close();
            }
          })
          .catch(err => {
            this.loading = false;
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000
              })
              .fire({
                icon: "error",
                title: err.data.message
              });
          });
      }
    }
  }
};
</script>

<style scoped></style>
